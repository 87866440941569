import Tippy from 'tippy.js';

new Tippy('.content-header__contact', {
    trigger: 'click',
    theme: 'orion-gray',
    placement: 'right',
    animateFill: 'false',
    performance: true,
    onShow: (tip) => {
        let content = tip.reference.nextElementSibling;
        tip.setContent(content.innerHTML);
    }
});

