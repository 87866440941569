import Tippy from 'tippy.js';

new Tippy('.specialist__contact-btn', {
  trigger: 'click',
  theme: 'orion',
  placement: 'right',
  animateFill: 'false',
  performance: true,
  onShow: (tip) => {
    let content = tip.reference.nextElementSibling;
    tip.setContent(content.innerHTML);
  }
});
