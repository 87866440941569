import Vue from 'vue';
import serviceTypes from '../service-types/service-types';
import serviceMenu from '../service-menu/service-menu';
import serviceMenuMobile from '../service-menu-mobile/service-menu-mobile';

export default function () {
    new Vue({
        el: '#main-slider',
        data() {
          return {
            activeSlide: 'operators',
            intervalId: null,
            videoTime: null,
            videoIsLoaded: false,
            videoPaused: false,
            isMobile: false
          };
        },
        mounted() {
            if (window.outerWidth < 992) {
              this.isMobile = true;
              this.videoTime = 5;
              this.startInterval();
              return;
            }
            const video = this.$el.querySelector('video');
            video.addEventListener('loadedmetadata', () => {
              this.videoIsLoaded = true;
              this.getVideoTime();
              this.play();
            });
            document.addEventListener('closeServiceMenu', () => {
              this.play();
            });
            document.addEventListener('openServiceMenu', () => {
              this.pause();
            });
        },
        watch: {
          activeSlide: function () {
            if (this.isMobile) {
              this.videoTime = 5;
              return;
            }
            this.getVideoTime();
          }
        },
        methods: {
            getVideoTime() {
              const video = this.$el.querySelector('video');
              this.videoTime = video.duration;
            },
            changeSlide(slide) {
                if (slide === this.activeSlide) {
                    return false;
                }
                this.activeSlide = slide;
                clearInterval(this.intervalId);
                this.startInterval();
            },
            startInterval() {
                this.intervalId = setInterval(() => {
                    if (this.activeSlide === 'operators') {
                        this.activeSlide = 'channels';
                    } else {
                        this.activeSlide = 'operators';
                    }
                }, this.videoTime * 1000);
            },
            pause() {
              const video = this.$el.querySelector('video');
              video.pause();
              video.currentTime = 0;
              clearInterval(this.intervalId);
              this.videoPaused = true;
            },
            play() {
              const video = this.$el.querySelector('video');
              video.currentTime = 0;
              video.play();
              this.startInterval();
              this.videoPaused = false;
            },
            onChangeType(type) {
              serviceMenu.isOpen = true;
              serviceMenu.activeType = type;
            },
            openMobileMenu() {
              serviceMenuMobile.isOpen = true;
            }
        },
        components: {
          'service-types': serviceTypes
        }
    });
}
