import Swiper from 'swiper/dist/js/swiper.min';

const elSliders = document.querySelectorAll('.partners-slider');


for (let i = 0, length = elSliders.length; i < length; i++) {

  let container = elSliders[i].querySelector('.partners-slider__container');
  let slides = container.querySelectorAll('.partners-slider__slide');
  let additionalOpt = {};
  if (slides.length > 4) {
    elSliders[i].classList.add('isInit');
    additionalOpt = {
      navigation: {
        nextEl: '.partners-slider__button--right',
        prevEl: '.partners-slider__button--left',
      },
    };

    new Swiper(container, {
      speed: 500,
      wrapperClass: 'partners-slider__wrap',
      slideClass: 'partners-slider__slide',
      slidesPerView: 4,
      spaceBetween: 60,
      breakpoints: {
        768: {
          slidesPerView: 'auto',
          spaceBetween: 30
        },
      },
      navigation: {
        nextEl: '.partners-slider__button--right',
        prevEl: '.partners-slider__button--left',
      }
    });
  } else {
    let nav = elSliders[i].querySelector('.partners-slider__nav');
    nav.style.display = 'none';

    if (window.innerWidth < 769 && slides.length > 3) {
      new Swiper(container, {
        speed: 500,
        wrapperClass: 'partners-slider__wrap',
        slideClass: 'partners-slider__slide',
        slidesPerView: 'auto',
        spaceBetween: 30
      });
    }
  }
}
