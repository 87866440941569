import Vue from 'vue';
import teamCard from 'components/team-card/team-card';

import { HTTP } from 'static/js/helpers/http';
import { API_ROUTES_EMPLOYEES } from 'static/js/constants';

export default function () {
  const teamList = new Vue({
    el: '#team-list',
    name: 'teamList',
    components: {
      teamCard
    },
    data() {
      return {
        offset: 0,
        limit: 8,
        pageLimit: 4,
        pageNumber: 1,
        isLoading: true,
        teamCardsList: {},
        newsLastRequest: {},
      };
    },
    mounted() {
      if (window.innerWidth > 1023) {
        this.limit = 999;
        this.pageLimit = 999;
      }
      this.getEmployees();
      // hide popovers on scroll
      // window.addEventListener('scroll', () => {
      //   this.hidePopovers();
      // });
    },
    methods: {
      showMore() {
        this.getEmployees();
      },
      getEmployees() {
        let reqOpts = {
          params: {
            _page: this.pageNumber,
            _limit: this.limit,
            _lang: window.config.api.lang
          },
        };

        HTTP.get(API_ROUTES_EMPLOYEES, reqOpts).then(resp => {
          return resp.data;
        }).then(listData => {
          this.isLoading = false;
          this.newsLastRequest = listData;
          this.addItemsToList();
          this.offset += this.pageLimit;
          this.pageNumber += 1;
        }).catch((e) => {
          console.error(e);
        });
      },
      addItemsToList() {
        let newData = {};
        newData = this.teamCardsList;
        if (this.offset > 0) {
          newData = [...newData, ...this.newsLastRequest];
        } else {
          newData = this.newsLastRequest;
        }
        this.teamCardsList = newData;
      }
    },
    computed: {
      isEndOfList() {
        return this.newsLastRequest.length < this.pageLimit;
      }
    }
  });
}
