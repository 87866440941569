import Vue from 'vue';
import VeeValidate from 'vee-validate/dist/vee-validate.min';
import ru from 'vee-validate/dist/locale/ru';
import en from 'vee-validate/dist/locale/en';
import axios from 'axios';
import 'static/js/plugins/inputmask.phone';
import Inputmask from 'inputmask';

const mainEl = '.feedback--team';
const formEl = '.feedback-form';

if (document.querySelector(mainEl)) {

  let phoneEl = null;

  Vue.use(VeeValidate, {
    events: 'change',
    trueField: 'phoneMask'
  });
  Vue.directive('mask', {
    bind: (el, binding) => {
      new Inputmask(binding.value).mask(el);
    }
  });

  VeeValidate.Validator.extend('phoneMask', {
    getMessage: field => 'The ' + field + ' value is not truthy.',
    validate: () => {
      return phoneEl.inputmask.isComplete();
    }
  });

  VeeValidate.Validator.localize('en', en);
  VeeValidate.Validator.localize('ru', ru);
  VeeValidate.Validator.localize('ru', {
    attributes: {
      name: 'имя',
      email: 'e-mail',
      phone: 'номер телефона',
      message: 'текст сообщения',
      file: 'резюме'
    }
  });

  new Vue({
    el: mainEl,
    data() {
      return {
        form: {
          name: null,
          phone: null,
          email: null,
          message: null,
          token: null,
          file: null
        },
        responseErrorText: null,
        responseSuccess: false
      };
    },
    mounted() {
      phoneEl = this.$refs.phone;
    },
    filters: {
      ellipsis(value) {
        return (value.leading > 20) ? value : value.substr(0, 20) + '...';
      }
    },
    methods: {
      handleAttach(event) {
        if (this.form.file) {
          event.preventDefault();
          this.form.file = null;
        } else {
          this.form.file = this.$refs.file.files[0];
        }
      },
      sendForm() {
        this.responseErrorText = null;

        let formData = new FormData();
        Object.keys(this.form).forEach((key) => {
          formData.append(key, this.form[key]);
        });

        axios.post(this.formUrl, formData).then((res) => {
          this.responseSuccess = true;
        }).catch((err) => {
          this.responseErrorText = err.response.data;
        });
      }
    },
    computed: {
      formUrl() {
        return document.querySelector(mainEl + ' ' + formEl).action;
      },
      formValidated() {
        return Object.keys(this.fields).every(field => {
          return this.fields[field] && this.fields[field].valid;
        });
      }
    }
  });
}
