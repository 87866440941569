import Vue from 'vue';

import { HTTP } from 'static/js/helpers/http';
import filterBar from 'components/filter/filter';

import { API_ROUTES_NEWS } from 'static/js/constants';

export default function () {

  const mediacenter = new Vue({
    el: '#mediacenter',
    name: 'mediacenter',
    components: {
      filterBar,
    },
    data() {
      return {
        filterName: '',
        offset: 0,
        pageLimit: 8,
        pageNumber: 1,
        isLoading: true,
        newsList: {},
        newsLastRequest: {},
      };
    },
    mounted() {
      this.setFilterName();
    },
    methods: {
      clickToCategory(filterName) {
        this.filterName = filterName === '*' ? null : filterName;
        window.history.pushState(null, null, this.filterName ? '/media-center/' + this.filterName : '/media-center');
      },
      showMore() {
        this.getNewsData();
      },
      getNewsData() {
        // Формируем объект с данными
        let reqOpts = {
          params: {
            _page: this.pageNumber,
            _lang: window.config.api.lang
          },
        };

        if (this.filterName !== '*') {
          reqOpts.params = Object.assign(reqOpts.params,
            {category: this.filterName});
        }

        HTTP.get(API_ROUTES_NEWS, reqOpts).then(resp => {
          return resp.data;
        }).then(listData => {
          this.isLoading = false;
          this.newsLastRequest = listData;
          this.addItemsToList();
          this.offset += this.pageLimit;
          this.pageNumber += 1;
        }).catch((e) => {
          console.error(e);
        });
      },
      addItemsToList() {
        let newData = {};
        newData = this.newsList;
        if (this.offset > 0) {
          newData = [...newData, ...this.newsLastRequest];
        } else {
          newData = this.newsLastRequest;
        }
        this.newsList = newData;
      },
      setFilterName() {
        let parsed = window.location.pathname.split('/');
        if (parsed.length === 3) {
          this.filterName = parsed[2];
        } else {
          this.filterName = '*';
        }
       }
    },
    computed: {
      isEndOfList() {
        return this.newsLastRequest.length < this.pageLimit;
      },
    },
    watch: {
      filterName() {
        this.pageNumber = 1;
        this.offset = 0;
        this.getNewsData();
      },
    },
    filters: {
      formatForDate(value) {
        let options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        };

        let date = new Date(value);

        return date.toLocaleString(window.config.api.lang, options); // среда, 31 декабря 2014 г. н.э. 12:30:00
      },
    },
  });
}
