const header = document.querySelector('.page__header');
const headerOffset = header.offsetTop;

let headerStickyToggle = () => {
  if (window.pageYOffset > headerOffset) {
    header.classList.add('page__header--sticky');
  } else {
    header.classList.remove('page__header--sticky');
  }
};

window.onscroll = () => {
  headerStickyToggle();
};

let menuActiveElement = '';

// make header inverse if services menu opened
document.addEventListener('openServiceMenu', () => {
  header.classList.add('page__header--inverse');
  menuActiveElement = document.querySelector('.header-menu__link--is-active');
  document.querySelector('.header-menu__item--is-active').classList.remove('header-menu__link--is-active');
  document.querySelector('.header-menu__item--services').classList.add('header-menu__link--is-active');
});
document.addEventListener('closeServiceMenu', () => {
  header.classList.remove('page__header--inverse');
  menuActiveElement.classList.add('header-menu__link--is-active');
  document.querySelector('.header-menu__item--services .header-menu__link').classList.remove('header-menu__link--is-active');
});
