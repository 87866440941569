import { parseUrl as getQuery } from 'query-string';

const playBtn = document.querySelectorAll('.content-video__play-btn');

let playVideo = (event) => {
  event.preventDefault();
  let item = event.target.parentNode.closest('.content-video');
  item.classList.add('content-video--play');
  let parsedUrl = getQuery(item.querySelector('iframe').src);
  let sizeOfQuery = Object.keys(parsedUrl.query).length;
  let param = sizeOfQuery ? '&autoplay=1' : '?autoplay=1';
  item.querySelector('iframe').src += param;
};

// playBtn.forEach((item) => {
Array.prototype.forEach.call(playBtn, function (item) {
  item.addEventListener('click', (event) => playVideo(event));
});

