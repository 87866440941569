import Swiper from 'swiper/dist/js/swiper.min';

const features = function () {
  const company = document.querySelector('.js-company-features');
  const slides = document.querySelectorAll('.js-company-features .js-company-slider .swiper-slide');

  if (company) {
    if (slides.length > 3) {
      company.classList.add('isNavShown');
    }
  }

  let companyFeaturesSlider = new Swiper('.company-features__slider-container', {
    slidesPerView: 3,
    spaceBetween: 75,
    navigation: {
      nextEl: '.company-features__slider-nav-arrow--right',
      prevEl: '.company-features__slider-nav-arrow--left',
    },
    breakpoints: {
      767: {
        slidesPerView: 'auto',
        spaceBetween: 50
      },
      991: {
        slidesPerView: 2,
        spaceBetween: 75
      }
    }
  });
};

features();
