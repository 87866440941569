import Swiper from 'swiper/dist/js/swiper.min';

const elSliders = document.querySelectorAll('.content-docs');

for (let i = 0, length = elSliders.length; i < length; i++) {

  let container = elSliders[i].querySelector('.content-docs__container');
  let slides = container.querySelectorAll('.content-docs__slide');
  let additionalOpt = {};
  elSliders[i].classList.add('isInit');
  if (slides.length > 4) {
    additionalOpt = {
      navigation: {
        nextEl: '.content-docs__button--right',
        prevEl: '.content-docs__button--left',
      },
    };
  } else {
    let nav = elSliders[i].querySelector('.content-docs__nav');
    nav.style = 'display:none';
  }

  new Swiper(container, {
    speed: 500,
    wrapperClass: 'content-docs__wrap',
    slideClass: 'content-docs__slide',
    slidesPerView: 4,
    spaceBetween: 60,
    breakpoints: {
      768: {
        slidesPerView: 'auto',
        spaceBetween: 40,
      },
    },
    additionalOpt,
  });
}
