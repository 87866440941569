import Vue from 'vue';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const serviceMenuMobile = new Vue({
  el: '#service-menu-mobile',
  data: function () {
    return {
      isOpen: false,
      types: window.app.serviceTypesList,
      list: window.app.serviceList,
      openList: [],
      isOpenState: false,
      isReady: false
    };
  },
  watch: {
    isOpen: function (value) {
      if (value) {
        disableBodyScroll(this.$refs.menu, { reserveScrollBarGap: true });
      } else {
        enableBodyScroll(this.$refs.menu, { reserveScrollBarGap: true });
      }
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.onResize);
    this.isReady = true;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    close() {
      this.isOpen = this.isOpenState = false;
    },
    toggleType(code) {
      const serviceIndex = this.openList.indexOf(code);
      if (serviceIndex === -1) {
        this.openList.push(code);
        return false;
      }
      this.openList.splice(serviceIndex, 1);
    },
    onResize() {
      if (window.innerWidth > 991) {
        if (this.isOpen) {
          this.isOpenState = true;
        }
        this.isOpen = false;
      } else {
        if (this.isOpenState) {
          this.isOpen = true;
        }
      }
    }
  }
});

export default serviceMenuMobile;


