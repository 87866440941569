import Swiper from 'swiper/dist/js/swiper.min';

export default function () {
  let isInit = false;
  let swiper;

  function destroy() {
    swiper.destroy();
    isInit = false;
  }

  function init() {
    if (isInit) {
      return false;
    }
    swiper = new Swiper('.js-company-trust-swiper', {
      wrapperClass: 'js-company-trust-wrapper',
      navigation: {
        nextEl: '.company-trust__slider-nav-arrow--right',
        prevEl: '.company-trust__slider-nav-arrow--left',
      },
      slidesPerView: 1,
      init: true
    });
    isInit = true;
  }
  function checkInit() {
    if (window.innerWidth < 767) {
      if (!isInit) {
        return false;
      }
      destroy();
    } else {
      init();
    }
  }
  checkInit();
  window.addEventListener('resize', function () {
    checkInit();
  });
  document.querySelector('.js-company-trust-more').addEventListener('click', function () {
    const items = document.querySelectorAll('.js-company-trust-item:not(.isActive)');
    if (items.length) {
      items[0].classList.add('isActive');
      if (!items[1]) {
        this.parentNode.style.display = 'none';
      }
    }
  });
}
