import serviceMenu from '../../service-menu/service-menu.js';
import serviceMenuMobile from '../../service-menu-mobile/service-menu-mobile.js';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let header = document.querySelector('.page__header');
let headerMenuMobile = document.querySelector('.header-menu-mobile');
let headerMenuTrigger = document.querySelector('.header-menu__trigger');

const headerMenuSpecial = document.querySelectorAll('.js-header-menu-services');

let mobileMenu = (state) => {
  if (state) {
    header.classList.add('page__header--inverse');
    headerMenuTrigger.classList.add('header-menu__trigger--is-active');
    headerMenuMobile.classList.add('header-menu-mobile--is-active');
    disableBodyScroll(headerMenuMobile, {reserveScrollBarGap: true});
  } else {
    header.classList.remove('page__header--inverse');
    headerMenuTrigger.classList.remove('header-menu__trigger--is-active');
    headerMenuMobile.classList.remove('header-menu-mobile--is-active');
    clearAllBodyScrollLocks();
  }
};

// toggle mobileMenu state by clicking on trigger
headerMenuTrigger.addEventListener('click', () => {
  if (!header.classList.contains('page__header--inverse')) {
    mobileMenu(true);
  } else {
    mobileMenu(false);
  }
});

// hide mobile menu if resolution been changed upper 767px
if (matchMedia) {
  let mq = window.matchMedia('(max-width: 767px)');
  mq.addListener(() => {
    if (!mq.matches && header.classList.contains('page__header--inverse')) {
      mobileMenu(false);
    }
  });
}


const headerMenuOpenServiceMenuMobile = () => {
  serviceMenuMobile.isOpen = true;
};
const headerMenuOpenServiceMenuDesktop = () => {
  if (!serviceMenu.isOpen) {
    serviceMenu.isOpen = true;
    serviceMenu.activeType = '';
  } else {
    document.dispatchEvent(serviceMenu.closeEvent);
    serviceMenu.isOpen = false;
    serviceMenu.activeType = '';
  }
};

const headerMenuOpenServiceMenu = () => {
  if (window.outerWidth < 992) {
    headerMenuOpenServiceMenuMobile();
    return true;
  }
  headerMenuOpenServiceMenuDesktop();
};

Array.prototype.forEach.call(headerMenuSpecial, item => {
  item.addEventListener('click', headerMenuOpenServiceMenu);
});

