import Vue from 'vue';

export default () => new Vue({
  name: 'Search',
  el: '.header-search',
  data() {
    return {
      isOpened: false
    };
  },
  methods: {
    showSearch() {
      this.isOpened = true;
    },
    closeSearch() {
      this.isOpened = false;
    }
  }
});
