import Swiper from 'swiper/dist/js/swiper.min';

let companyAwardsSlider = new Swiper('.company-awards__slider-container', {
  slidesPerView: 3,
  spaceBetween: 75,
  navigation: {
    nextEl: '.company-awards__slider-nav-arrow--right',
    prevEl: '.company-awards__slider-nav-arrow--left',
  },
  breakpoints: {
    480: {
      slidesPerView: 'auto',
      spaceBetween: 20
    },
    991: {
      slidesPerView: 2
    }
  }
});

