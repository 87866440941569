import Swiper from 'swiper/dist/js/swiper.min';

let el = document.querySelectorAll('.js-content-slider');

Array.prototype.forEach.call(el, slider => {
  let sliderCounter = slider.querySelectorAll('.content-slider__item');
  let defOptions = {
    loop: true,
    slidesPerView: 1
  };
  if (sliderCounter.length > 1) {
    defOptions.pagination = {
      el: slider.querySelector('.content-slider__counter'),
      type: 'fraction',
      currentClass: 'content-slider__counter-item--active',
      totalClass: 'content-slider__counter-item--length',
      renderFraction: (cur, idx) => {
        return `<div class="content-slider__counter-item ${cur}"></div>
        <div class="content-slider__counter-item"> из</div>
        <div class="content-slider__counter-item ${idx}"></div>`;
      },
    };
    defOptions.navigation = {
      nextEl: '.content-slider__button--right',
      prevEl: '.content-slider__button--left',
    };
    new Swiper(slider.querySelector('.swiper-container'), defOptions);
  } else {
    let buttons = slider.querySelector('.content-slider__buttons');
    buttons.style.display = 'none';
  }


});
