import serviceMenu from '../service-menu/service-menu';
import serviceMenuMobile from '../service-menu-mobile/service-menu-mobile';

const openServiceMenu = document.querySelector('.js-company-open-service-menu');

if (openServiceMenu) {
  openServiceMenu.addEventListener('click', function () {
    const windowWidth = window.innerWidth;
    if (windowWidth > 991) {
      serviceMenu.isOpen = true;
      return true;
    }
    serviceMenuMobile.isOpen = true;
  });
}

