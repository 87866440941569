import Vue from 'vue';
import serviceTypes from '../service-types/service-types';
import ServiceMenuTextSize from './service-menu-text-size';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const serviceMenu = new Vue({ // чтобы могли получить доступ из компонента слайдера
  el: '#service-menu',
  methods: {
    onChangeType(type) {
      this.activeType = type;
    },
    close() {
      this.isOpen = this.isOpenState = false;
      document.dispatchEvent(this.closeEvent);
    },
    onResize() {
      if (window.innerWidth < 992) {
        if (this.isOpen) {
          this.isOpenState = true;
        }
        this.isOpen = false;
      } else {
        if (this.isOpenState) {
          this.isOpen = true;
        }
      }
    }
  },
  watch: {
    isOpen: function (value) {
      if (value) {
        disableBodyScroll(this.$refs.menu, { reserveScrollBarGap: true });
        document.dispatchEvent(this.openEvent);
      } else {
        enableBodyScroll(this.$refs.menu);
      }
    }
  },
  updated: function () {
    this.$nextTick(function () {
      this.textResize.adaptive();
    });
  },
  mounted: function () {
    this.textResize = new ServiceMenuTextSize();
    window.addEventListener('resize', this.onResize);
    this.closeEvent = document.createEvent('Event');
    this.closeEvent.initEvent('closeServiceMenu', true, true);
    this.openEvent = document.createEvent('Event');
    this.openEvent.initEvent('openServiceMenu', true, true);
    this.isReady = true;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  data: function () {
    return {
      isOpen: false,
      activeType: 'one',
      textResize: {},
      list: window.app.serviceList,
      isOpenState: false,
      closeEvent: {},
      openEvent: {},
      isReady: false
    };
  },
  components: {
    'service-types': serviceTypes
  }
});

export default serviceMenu;

