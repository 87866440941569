import Swiper from 'swiper/dist/js/swiper.min';
import Tippy from 'tippy.js';

const elSlider = document.querySelector('.specialists__slider');

let specialistsSlider = new Swiper(elSlider, {
  init: false,
  speed: 500,
  navigation: {
    nextEl: '.specialists__slider-button--right',
    prevEl: '.specialists__slider-button--left',
  },
  loop: true,
  pagination: {
    el: '.specialists__counter',
    type: 'fraction',
    currentClass: 'specialists__counter-item--active',
    totalClass: 'specialists__counter-item--length',
    renderFraction: (cur, idx) => {
      return `<div class="specialists__counter-item ${cur}"></div>
        <div class="specialists__counter-item"> из</div>
        <div class="specialists__counter-item ${idx}"></div>`;
    }
  },
  autoHeight: true,
  autoplay: {
    delay: 5000,
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  }
});

let popover = () => {
  return new Tippy('.js-specialists-contact-btn', {
    trigger: 'click',
    theme: 'orion',
    placement: 'right',
    animateFill: 'false',
    performance: true,
    onShow: (tip) => {
      specialistsSlider.autoplay.stop();
      const content = tip.reference.closest('.specialists__item').querySelectorAll('.specialists__popover');
      const clone = content[0].cloneNode(true);
      tip.setContent(clone.innerHTML);
    },
    onHide: () => specialistsSlider.autoplay.start(),
  });
};

if (elSlider) {
  specialistsSlider.on('init', () => popover());
  specialistsSlider.init();
}
