import Swiper from 'swiper/dist/js/swiper.min';
import Vue from 'vue';

export default function () {
  function initSwiper() {
    new Swiper('.js-company-license-swiper', {
      wrapperClass: 'js-company-license-swiper-wrapper',
      slidesPerView: 'auto',
      init: true,
      breakpointsInverse: true,
      breakpoints: {
        992: {
          noSwiping: true
        },
      }
    });
  }
  new Vue({
    el: '.company-license',
    name: 'company-license',
    data: function () {
      return {
        activeText: null
      };
    },
    methods: {
      showAbout: function (event) {
        this.activeText = event.target.querySelector('.company-license__item-info').innerHTML;
      },
      hideAbout: function () {
        this.activeText = null;
      },
    },
    mounted: function () {
      initSwiper();
    }
  });
}
