import Vue from 'vue';
import companyVideoPopup from './company-video-popup/company-video-popup';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function () {

  new Vue({
    el: '#company-video',
    data() {
      return {
        isPlay: false,
      };
    },
    methods: {
      playVideo() {
        this.isPlay = true;
        disableBodyScroll(document.querySelector('#company-video'), {reserveScrollBarGap: true});
      },
      closePopup() {
        this.isPlay = false;
        enableBodyScroll(document.querySelector('#company-video'), {reserveScrollBarGap: true});
      }
    },
    components: {
      'company-video-popup': companyVideoPopup
    }
  });
}
