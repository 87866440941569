export const HEADERS = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Methods': '*',
	'Access-Control-Allow-Headers': '*'
};

export const MEDIA_DESKTOP = window.matchMedia('screen and (min-width: 768px)').matches;
export const MEDIA_MOBILE = window.matchMedia('screen and (max-width: 767px)').matches;

export const {
  api: {
    root: API_ROOT = '/api',
    routes: {
      news: API_ROUTES_NEWS = 'news',
      employees: API_ROUTES_EMPLOYEES = 'employees'
    } = {},
  } = {},
  svg: {
    root: IMG_ROOT = '/static/img/',
    filename: SVG_FILENAME = 'svg-symbols.svg',
    revision: SVG_REVISION = Math.floor(Math.random() * (20)),
  } = {},
} = window.config || {};
