window.contactsMap = () => {
  const map = new ymaps.Map('contactsmap', {
    center: window.contactsMapConfig.map.center,
    controls: window.contactsMapConfig.map.controls,
    zoom: window.contactsMapConfig.map.zoom
  });

  // add placemarks
  window.contactsMapConfig.placemarks.map(item => {
    let placemark = new ymaps.Placemark(item.position, {}, {
      iconLayout: 'default#image',
      iconImageHref: item.iconImageHref,
      iconImageSize: item.iconImageSize,
      iconImageOffset: item.iconImageOffset
    });
    map.geoObjects.add(placemark);
  });

  // colorize map
  map.panes.get('ground').getElement().style.filter = 'grayscale(100%)';

  // disable scroll on map
  map.behaviors.disable('scrollZoom');

  // disable drag map
  map.behaviors.disable('drag');

  // enable drag if click detected on map
  document.getElementById('contactsmap').addEventListener('click', () => map.behaviors.enable('drag'));
};
