export default class Btn {
  constructor() {
    this.$button = $('.btn:not(disabled)');
    const self = this;
    this.$button.each(function () {
      self.eventHandlers($(this));
    });
  }

  hover(e, $button) {
    const $hover = $button.find('.btn__hover');
    const parentOffset = $button.offset(),
        relX = e.pageX - parentOffset.left,
        relY = e.pageY - parentOffset.top;
    $hover.css({top: relY, left: relX});
  }

  eventHandlers($button) {
    $button
      .on('mouseenter', (e) => {
          this.hover(e, $button);
      })
      .on('mouseout', (e) => {
          this.hover(e, $button);
      });
  }
}
