'use strict';

import 'core-js/fn/object/assign';
import 'core-js/fn/array/for-each';
import 'core-js/fn/symbol';
import 'core-js/fn/promise';
import objectFitImages from 'object-fit-images';
import objectFitVideos from 'object-fit-videos';
import 'element-closest';
require('goodshare.js');

objectFitImages();
objectFitVideos();

import svgInject from 'static/js/helpers/svgInject';
import forEach from 'static/js/helpers/foreach';
import debug from 'components/debug/debug';
import Btn from 'components/controls/btn/btn';

import mainSlider from 'components/main-slider/main-slider';
import 'components/header/header';
import mediacenter from 'components/media-center/media-center';
import 'components/header/header-menu/header-menu';
import search from 'components/header/header-search/header-search';
import 'components/feedback/feedback-main/feedback-main';
import 'components/feedback/feedback-team/feedback-team';
import 'components/feedback/feedback-connection/feedback-connection';
import 'components/page-contacts/page-contacts';
import 'components/company-service/company-service';
import 'components/company-history/company-history';
import 'components/company-features/company-features';
import 'components/company-awards/company-awards';
import 'components/specialists/specialists';
import 'components/partners-slider/partners-slider';
import VHChromeFix from 'static/js/helpers/vh-fix.js';
import 'components/content/content-docs/content-docs';
import 'components/content/content-slider/content-slider';
import companyTeamSwiper from 'components/company-team/company-team';
import channelsList from 'components/channels-list/channels-list';
import companyVideo from 'components/company-video/company-video';
import companyTrustSwiper from 'components/company-trust/company-trust';
import companyLicense from 'components/company-license/company-license';
import teamList from 'components/team-list/team-list';
import 'components/specialist/specialist';
import 'components/content/content-employee-quote/content-employee-quote';
import 'components/content-header/content-header';
import 'components/content/content-video/content-video';

// Vue components
import 'components/service-menu/service-menu';
import 'components/service-menu-mobile/service-menu-mobile';
function run(selector, func, args = null) {
  let node = $(selector);
  if (node.length) {
    func(args);
  }
}

search();

run('#main-slider', mainSlider);
run('#mediacenter', mediacenter);
run('.js-company-team-swiper', companyTeamSwiper);
run('.channels-list', channelsList);
run('#company-video', companyVideo);
run('.js-company-trust-swiper', companyTrustSwiper);
run('#company-license', companyLicense);
run('#team-list', teamList);

// Эта штука позволяет обойти баг с кривым вычислением vh-единиц
// и сделать блок на всю высоту экрана
let options = [
  {
    selector: ['.main-slider', '.header-menu-mobile'],
    once: true,
    vh: 100,
    offset: 55
  }
];
new VHChromeFix(options);

svgInject();

new Btn();

debug();
