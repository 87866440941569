export default class ServiceMenuTextSize {
  constructor() {
    this.serviceVueMenu = document.querySelector('#service-menu');
    this.serviceMenu = document.querySelector('.service-menu');
    this.serviceMenuList = document.querySelector('.service-menu__list');
    this.serviceMenuContainer = document.querySelector('.service-menu__container');
    this.fontSize = this.getFontSize();
    this.eventHandlers();
    this.resizeTimeout = null;
  }
  adaptive() {
    while (this.isOverflown && this.isDisplay) {
      this.changeFontSize(-1);
    }
    while (this.isSmall && this.isDisplay) {
      this.changeFontSize(1);
    }
  }
  getFontSize() {
    const style = window.getComputedStyle(this.serviceMenuList).getPropertyValue('font-size');
    return parseFloat(style);
  }
  changeFontSize(increment) {
    this.fontSize += increment;
    this.serviceMenuList.style.fontSize = this.fontSize + 'px';
  }
  get isOverflown() {
    return $(this.serviceMenuList).height() > $(this.serviceMenuContainer).height();
  }
  get isSmall() {
    return ($(this.serviceMenuContainer).height() - $(this.serviceMenuList).height() > 100) && this.fontSize < 34;
  }
  get isDisplay() {
    return getComputedStyle(this.serviceMenu).display !== 'none' &&
      getComputedStyle(this.serviceVueMenu).display !== 'none';
  }
  eventHandlers() {
    window.addEventListener('resize', () => {
      if (!this.isDisplay) {
        clearTimeout(this.resizeTimeout);
        return false;
      }
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.adaptive();
      }, 50);
    });
  }
}
