import Swiper from 'swiper/dist/js/swiper.min';

export default function () {
  new Swiper('.js-company-team-swiper', {
    wrapperClass: 'js-company-team-swiper-wrapper',
    slidesPerView: 'auto',
    init: true,
    breakpointsInverse: true,
    breakpoints: {
      992: {
        noSwiping: true
      },
    }
  });
}
