import debounce from 'lodash.debounce';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default {
  name: 'filter-bar',
  template: '#filterBar',
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    filter: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      overlay: document.createElement('DIV'),
      winWidth: window.innerWidth
    };
  },
  mounted() {
    document.body.appendChild(this.overlay);
    this.overlay.classList.add('modal-overlay');
    this.overlay.addEventListener('click', this.hideMobilePopup.bind(this));
    this.$on('filter', this.hideMobilePopup.bind(this));
    window.addEventListener('resize', debounce(() => {
      this.winWidth = window.innerWidth;
    }, 500));
  },
  computed: {
    isMobile() {
      return this.winWidth < 576;
    }
  },
  methods: {
    showMobilePopup() {
      this.$refs.modal.classList.add('isShown');
      this.overlay.classList.add('isShown');
      disableBodyScroll(this.$refs.modal, { reserveScrollBarGap: true });
    },
    hideMobilePopup() {
      this.$refs.modal.classList.remove('isShown');
      this.overlay.classList.remove('isShown');
      enableBodyScroll(this.$refs.modal, { reserveScrollBarGap: true });
    },
  },

};
