import Vue from 'vue';
import Tippy from 'tippy.js';

const teamCard = Vue.component('team-card', {
  template: '#team-card',
  props: ['item'],
  data() {
    return {
    };
  },
  mounted() {
    new Tippy('.js-popover-services', {
      trigger: 'click',
      theme: 'orion-gray',
      placement: 'right',
      animateFill: 'false',
      performance: true,
      onShow: (tip) => {
          let content = tip.reference.nextElementSibling;
          tip.setContent(content.innerHTML);
      }
    });
    new Tippy('.js-popover', {
      trigger: 'click',
      theme: 'orion-gray',
      placement: 'right',
      animateFill: 'false',
      performance: true,
      onShow: (tip) => {
          let content = tip.reference.nextElementSibling;
          tip.setContent(content.innerHTML);
      }
    });
  }
});

export default teamCard;
