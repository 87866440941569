import mixitup from 'mixitup';
import MicroModal from 'micromodal';

const channelsList = () => {
  const mixer = mixitup('.channels-list__box', {
      selectors: {
          target: '.channels-list-item'
      },
      animation: {
          duration: 300
      }
  });

  MicroModal.init({
    awaitCloseAnimation: true
  });

  // Array.prototype.forEach.call(array, function (argument) {

  // .forEach((item) => {
  Array.prototype.forEach.call(document.querySelectorAll('.channels-list-filter__item'), function (item) {
    item.addEventListener('click', (event) => {
      let clickedModalItem = event.target.closest('.channels-list-filter__item');
      let clickedModalItemId = clickedModalItem.dataset.filter;
      Array.prototype.forEach.call(document.querySelectorAll('.channels-list-filter__item'), function (el) {
      // .forEach((el) => {
        el.classList.remove('btn--pink');
      });

      clickedModalItem.classList.add('btn--pink');
      Array.prototype.forEach.call(document.querySelectorAll('.channels-list-filter__modal-item'), function (el) {
      // .forEach((el) => {
        el.classList.remove('channels-list-filter__modal-item--active');
      });
      document.querySelector('.channels-list-filter__modal-item[data-filter="' + clickedModalItemId + '"]').classList.add('channels-list-filter__modal-item--active');
      document.querySelector('.channels-list-filter__modal').classList.remove('is-open');
    });
  });

  // .forEach((modalItem) => {
  Array.prototype.forEach.call(document.querySelectorAll('.channels-list-filter__modal-item'), function (modalItem) {
    modalItem.addEventListener('click', (event) => {
      let clickedModalItem = event.target.closest('.channels-list-filter__modal-item');
      let clickedModalItemId = clickedModalItem.dataset.filter;
      Array.prototype.forEach.call(document.querySelectorAll('.channels-list-filter__modal-item'), function (el) {
      // .forEach((el) => {
        el.classList.remove('channels-list-filter__modal-item--active');
      });
      clickedModalItem.classList.add('channels-list-filter__modal-item--active');
      Array.prototype.forEach.call(document.querySelectorAll('.channels-list-filter__item'), function (el) {
      // .forEach((el) => {
        el.classList.remove('btn--pink');
      });
      document.querySelector('.channels-list-filter__item[data-filter="' + clickedModalItemId + '"]').classList.add('btn--pink');
      document.querySelector('.channels-list-filter__modal').classList.remove('is-open');
    });
  });
};

export default channelsList;
